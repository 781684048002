import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/layout';
import PostList, { PostNode } from '../components/post-list';
import SEO, { SeoModel } from '../components/seo';

interface CategoryTemplateProps {
  location: Location,
  readonly data: {
    allMarkdownRemark: {
      totalCount: number,
      edges: ({node: PostNode})[]
    },
  },
  pageContext: {
    category: string,
  }
}

export const categoryPageQuery = graphql`
  query CategoryTemplateQuery($category: String) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: {collection: {eq: "archive"}}, frontmatter: { categories: { in: [$category] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "nl-NL")
            title
            author
            categories
            source_url
          }
        }
      }
    }
  }
`;

const CategoryTemplate: React.FC<CategoryTemplateProps> = ({
  location,
  data,
  pageContext
}) => {
  const {allMarkdownRemark} = data;
  const {category} = pageContext;

  const seo: SeoModel = {
    title: `${category}`
  };

  return (
    <Layout location={location}>
      <SEO model={seo} />
      <div className="mt-5" />
      <section id="archive" className={`bg-white text-left py-5`}>
        <h1 className="text-center mt-5">Nieuwsarchief</h1>
        <h2 className="text-center mb-5"><span className="badge badge-primary">{category}</span></h2>
        <div className="container">
          <PostList posts={allMarkdownRemark.edges} hideCategoryBadge={true} />
        </div>
      </section>
    </Layout>
  );
}

export default CategoryTemplate;
